import {useLocation} from '@reach/router';
import Layout from 'components/layout';
import SEO from 'components/seo';
import {Link} from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, {useEffect} from 'react';
import Style from 'styles/service.module.scss';

import Image from '../components/image';

const Service = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, [location.hash]);

  return (
    <Layout>
      <SEO title='Service' path='service' />
      <section className={Style.serviceHeading}>
        <div className={Style.serviceHeading__inner}>
          <h1 className={Style.serviceHeading__title}>SERVICE</h1>
        </div>
      </section>
      <section className={Style.serviceIntro}>
        <div className={Style.serviceIntro__inner}>
          <div className={Style.serviceIntro__wrapper}>
            <div className={Style.serviceIntro__headWrapper}>
              <h2 className={Style.serviceIntro__head}>
                <span className={Style.serviceIntro__uppercase}>W</span>hat
                <br />
                <span className={Style.serviceIntro__uppercase}>W</span>e
                <br />
                <span className={Style.serviceIntro__uppercase}>O</span>ffer
              </h2>
            </div>
            <div className={Style.serviceIntro__contentWrapper}>
              <div className={Style.serviceIntro__content}>
                <p>
                  イーゼのコンセプトは、イーコマースを最適化するという原点から自社開発以外にも様々な事案に取り組んでます。
                </p>
                <p>
                  長年自社サービス開発を通して培ってきた、ユーザー、ステークホルダーのニーズなどをバックボーンとし、我々が成せるサービスやソリューションづくりに励み、一層高い価値の提供を目指しています。
                </p>
                <p>
                  市場や関係者様の要望に対し、イーゼ一丸となって、課題達成・問題解決、価値創造することに喜びと誇りを持ち、躍進します。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={Style.serviceContainer}>
        <div className={Style.serviceContainer__inner}>
          <section className={Style.serviceSes} id="ses">
            <div className={Style.serviceSes__inner}>
              <div className={Style.serviceItem}>
                <div className={Style.serviceItem__header}>
                  <h2 className={Style.serviceItem__head}>Projects</h2>
                </div>
                <div className={Style.serviceItemOutline}>
                  <div
                    className={Style.serviceItemOutline__img}
                    data-sal="fade"
                    data-sal-duration="300"
                    data-sal-delay="300"
                    data-sal-easing="ease-out"
                  >
                    <Image filename="img_service_comachi.png" />
                  </div>
                  <div className={Style.serviceItemOutline__txt}>
                    <h2 className={Style.serviceItemOutline__head}>自社開発のショッピングカートシステム</h2>
                    <p>
                      自社開発のショッピングカートシステム「comachicart(コマチカート)」はサービスインしたばかりの新しいショッピングカートシステムです。
                    </p>
                    <p>
                      豊富なデザインテンプレートを備え、多彩な機能でECサイトを簡単に構築。
                      直感的な操作性によりお客様のECサイト運営をサポートします。
                      パソコン、スマートフォン、タブレットなど様々な端末での表示に対応しており、HTMLやCSSの知識不要ですぐにお店を開くことができます。
                    </p>
                    <p>わかりやすくシンプルな料金体系で、初めてのECサイト開設でも安心です。</p>
                    <Link to="/comachicart/">
                      <Image className={Style.serviceItemOutline__comachiBanner} filename='bnr_service_comachi.png' />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={Style.serviceSes} id='ses'>
            <div className={Style.serviceSes__inner}>
              <div className={Style.serviceItem}>
                <div className={Style.serviceItem__header}>
                  <h2 className={Style.serviceItem__head}>System Engineering Service</h2>
                </div>
                <div className={Style.serviceItemOutline}>
                  <div
                    className={Style.serviceItemOutline__img}
                    data-sal='fade'
                    data-sal-duration='300'
                    data-sal-delay='300'
                    data-sal-easing='ease-out'
                  >
                    <Image filename='people.jpg' />
                  </div>
                  <div className={Style.serviceItemOutline__txt}>
                    <h2 className={Style.serviceItemOutline__head}>優秀な人材をご提供</h2>
                    <p>
                      WEBシステムの開発・検証・運用・保守といったサービス・ソリューションの提供には欠かせないエンジニア達を取引先へ提案し、技術的な
                      サービスを提供をしています。
                    </p>
                    <p>
                      単なるエンジニア派遣だけにとどまらず、日々蓄積・磨かれるITスキル、業界・業種別のノウハウ等を活用し、システムエンジニアリング内外にある課題解決を実践しています。
                    </p>
                    <p>
                      弊社では、技術スキルはもちろん、コミュニケーション力や従業員の士気も重視、フォローアップしており、人間力の高いエンジニアが提供できます。
                    </p>
                  </div>
                </div>
                <div className={Style.serviceItemOutline}>
                  <div
                    className={Style.serviceItemOutline__img}
                    data-sal="fade"
                    data-sal-duration="300"
                    data-sal-delay="300"
                    data-sal-easing="ease-out"
                  >
                    <Image filename="leaf.jpg" />
                  </div>
                  <div className={Style.serviceItemOutline__txt}>
                    <h2 className={Style.serviceItemOutline__head}>過去の実績</h2>
                    <p>
                      これまでWEBシステム開発をベースに、ECサイト構築、様々な業界におけるWEBシステム開発、SNSサービス開発、金融パッケージやグループウェア開発などを手掛けています。
                    </p>
                    <p>
                      フルスクラッチでの開発、レガシーなシステムのマイグレーション、エンハンス等も幅広く対応します。
                      このような実績の多くが下流工程から入る訳ではなく、要件やヒアリングフェーズから参画してます。
                    </p>
                    <p>数々の成功実績がさらに多くの業務機会を創出し、会社を一層成長させています。</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={Style.serviceDevelopment} id='development'>
            <div className={Style.serviceDevelopment__inner}>
              <div className={Style.serviceItem}>
                <div className={Style.serviceItem__header}>
                  <h2 className={Style.serviceItem__head}>Development</h2>
                </div>
                <div className={Style.serviceItemOutline}>
                  <div
                    className={Style.serviceItemOutline__img}
                    data-sal='fade'
                    data-sal-duration='300'
                    data-sal-delay='300'
                    data-sal-easing='ease-out'
                  >
                    <Image filename='arrow.jpg' />
                  </div>
                  <div className={Style.serviceItemOutline__txt}>
                    <h2 className={Style.serviceItemOutline__head}>最大限のソリューション</h2>
                    <p>イーゼでは自社製品の開発や受託開発も行っています。</p>
                    <p>
                      お客様のニーズや要求のヒアリングを通じて多角的な視点からソリューションをご提案するとともに、ご要望にマッチしたシステムの開発を行います。
                    </p>
                    <p>
                      また、納品して終了するのではなく、納品後のフォローやメンテナンス体制も整っており、お客様を全面的にサポートすることが可能です。
                    </p>
                  </div>
                </div>
                <div className={Style.serviceItemOutline}>
                  <div
                    className={Style.serviceItemOutline__img}
                    data-sal="fade"
                    data-sal-duration="300"
                    data-sal-delay="300"
                    data-sal-easing="ease-out"
                  >
                    <Image filename="team.jpg" />
                  </div>
                  <div className={Style.serviceItemOutline__txt}>
                    <h2 className={Style.serviceItemOutline__head}>過去の実績</h2>
                    <p>
                      これまで海外向け通信機器のレンタルアプリ開発、医療機関の予約システム開発、美容室向け予約システムのパフォーマンスチューニング、ブライダル業界の内部システムの開発などを請け負いました。
                    </p>
                    <p>SES事業を初めとする様々なプロジェクト経験があるエンジニアが開発を担います。</p>
                    <p>
                      自社製品のショッピングカートシステムcomachicart(コマチカート)や自社で使用する勤怠システムなど、
                      社内開発の経験から既にエンジニア組織が形成されているので円滑にプロジェクトを進行できることが私たちの強みです。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Service;
